/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import { IconButton } from '@atlaskit/button/new';
import MenuIcon from '@atlaskit/icon/glyph/menu';
import CloseIcon from '@atlaskit/icon/glyph/editor/close';
import SearchIcon from '@atlaskit/icon/glyph/search';
import { Anchor } from '@atlaskit/primitives';
import { useLocation } from '@reach/router';

import { hideOnDesktopNavigationStyles, gutterStyles, Logo } from '@af/design-system-docs-ui';
import { AtlassianIcon } from '@atlaskit/logo';
import { media } from '@atlaskit/primitives/responsive';
import Drawer from '@atlaskit/drawer';
import Inline from '@atlaskit/primitives/inline';
import { token } from '@atlaskit/tokens';

import Settings from './settings';
import SideNavigation, { type SideNavigationItem } from './side-navigation';

const fullLogoStyles = css({
	display: 'none',
	height: '24px',

	[media.above.sm]: {
		display: 'block',
	},
});

const iconLogoStyles = css({
	display: 'flex',

	[media.above.sm]: {
		display: 'none',
	},
});

const headerStyles = css({
	display: 'grid',
	gridTemplateAreas: 'hamburger logo search',
	gridTemplateColumns: '7.5rem auto 7.5rem',
	alignItems: 'center',
	height: '100%',
	background: token('elevation.surface', '#FFFFFF'),
	borderBlockEnd: `1px solid ${token('color.border', '#091E4224')}`,
});

const hamburgerStyles = css({
	// Pull left to align button icon with left gutter
	marginInlineStart: `${token('space.negative.100', '-8px')}`,
});

const drawerHeaderStyles = css({
	paddingInline: token('space.150', '12px'),
	position: 'sticky',
	insetBlockStart: 0,
	background: token('elevation.surface.overlay', '#FFFFFF'),
	zIndex: 1,
});

type MobileHeaderProps = {
	navigation: SideNavigationItem[];
	onSearchOpen?(): void;
};

const MobileHeader = ({ navigation, onSearchOpen }: MobileHeaderProps) => {
	const { pathname } = useLocation();
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);

	const handleSidebarOpen = useCallback(() => {
		setIsSidebarOpen(true);
	}, []);

	const handleSidebarClose = useCallback(() => {
		setIsSidebarOpen(false);
	}, []);

	// Close sidebar drawer when navigation changed.
	useEffect(() => {
		setIsSidebarOpen(false);
	}, [pathname]);

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
		<header css={[headerStyles, gutterStyles, hideOnDesktopNavigationStyles]}>
			<div css={hamburgerStyles}>
				<IconButton
					appearance="subtle"
					onClick={handleSidebarOpen}
					icon={MenuIcon}
					aria-expanded={isSidebarOpen}
					label="Site navigation"
				/>
				<Drawer
					onClose={handleSidebarClose}
					isOpen={isSidebarOpen}
					label="Site Navigation"
					// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
					overrides={{
						Sidebar: {
							component: () => null,
						},
					}}
				>
					<div css={drawerHeaderStyles}>
						<Inline alignInline="end">
							<IconButton
								autoFocus
								appearance="subtle"
								onClick={handleSidebarClose}
								icon={CloseIcon}
								label="Close navigation"
							/>
						</Inline>
					</div>
					<SideNavigation device="mobile" navigation={navigation} />
				</Drawer>
			</div>

			<Inline alignInline="center">
				<Anchor href="/">
					<div css={fullLogoStyles}>
						<Logo />
					</div>
					<div css={iconLogoStyles}>
						<AtlassianIcon appearance="brand" size="small" />
					</div>
				</Anchor>
			</Inline>

			<Inline space="space.100" alignInline="end">
				<IconButton
					appearance="subtle"
					onClick={onSearchOpen}
					icon={SearchIcon}
					label="Open search"
				/>
				<Settings />
			</Inline>
		</header>
	);
};

export default MobileHeader;
